import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import Button from '../../../components/button/button';
import { CreateSubscriptionResponse } from '../../../model/apiResponses/CreateSubscriptionResponse';
import { CreateSubscription } from '../../../model/createSubscription';

export interface Props {
    t: (path: string, params?: any) => string;
    createSubscription: CreateSubscription;
    createSubscriptionResponse: CreateSubscriptionResponse;
    isRegisterFlux: boolean;
    onNextStep: (createSubscription: CreateSubscription, hasErrors: boolean) => void;
    onBack: () => void;
    showErrors?: boolean;
}

export const FinishStep = (props: Props): JSX.Element => {
    const { twoYearsPromotion } = props.createSubscription;
    const { totalPaid } = props.createSubscriptionResponse;
    const totalValue = twoYearsPromotion ? 837.60 : 418.80;

    return (
        <div className='finish-step'>
            <Row style={{ width: '100%' }}>
                <Col md={{ size: '6', offset: '3' }} xs={{ size: '12' }}>
                    <div className='finish-step__success'>
                        <div className='finish-step__success__icon' />
                        <h1 className='finish-step__success__title'>{props.t('registerSubscription.finishStep.successTitle')}</h1>
                        <h2 className='finish-step__success__subtitle'>{props.t('registerSubscription.finishStep.successSubtitle')}</h2>
                    </div>
                    <div className='finish-step__line' />
                    <div className='finish-step__details'>
                        <p className='finish-step__details__title'>{props.t('registerSubscription.finishStep.details.title').toUpperCase()}</p>
                        <div className='finish-step__details__row'>
                            <span className='finish-step__details__row__label'>
                                {props.t('registerSubscription.finishStep.details.label1', { value: twoYearsPromotion ? '2' : '1' })}
                            </span>
                            <div className='finish-step__details__row__line' />
                            <span className='finish-step__details__row__label--big'>{`R$${totalPaid.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}</span>
                        </div>
                        <div className='finish-step__details__row'>
                            <span className='finish-step__details__row__label'>{props.t('registerSubscription.finishStep.details.label2')}</span>
                            <div className='finish-step__details__row__line' />
                            <span className='finish-step__details__row__label'>{`R$${(totalValue - totalPaid).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}</span>
                        </div>
                    </div>
                    <div className='finish-step__line' />
                    <div className='finish-step__email-confirmation'>
                        <div className='message-box'>
                            <div className='send-svg' />
                            <p>{props.t('register.confirmation')}</p>
                        </div>
                    </div>
                    <div className='steps__footer' style={{ justifyContent: 'center' }}>
                        <Button
                            label={
                                props.isRegisterFlux ? props.t('registerSubscription.finishStep.buttonLabel') : props.t('registerSubscription.finishStep.buttonLabelSubscription')
                            }
                            onPress={() => props.onNextStep(props.createSubscription, false)}
                            style={{ maxWidth: 163, paddingLeft: 10 }}
                            rightIcon={<div className='diagnapp-carousel__advance-icon' />}
                        />
                    </div>
                    <div style={{ marginBottom: '79px' }} />
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FinishStep) as React.ComponentType<Omit<Props, 't'>>;

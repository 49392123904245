import { FormControl } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import Button from '../../../components/button/button';
import CustomToast from '../../../components/custom-toast/custom-toast';
import TextFieldMeds from '../../../components/text-field-meds/text-field-meds';
import { CustomerRegister } from '../../../model/customer-register';
import RegisterService from '../../../services/registerService';
import { validateEmail } from '../../../shared/util/utils';

export interface Props {
    t: (path: string, params?: any) => string;
    createSubscription: CustomerRegister;
    onNextStep: (createSubscription: CustomerRegister, hasErrors: boolean) => void;
    showErrors?: boolean;
}

export const LoginStep = (props: Props): JSX.Element => {
    const [email, setEmail] = useState<string>(props.createSubscription.email);
    const [emailError, setEmailError] = useState<boolean | undefined>(undefined);
    const [emailErrorText, setEmailErrorText] = useState<string | undefined>(props.t('registerSubscription.loginStep.errors.email'));
    const [name, setName] = useState<string | undefined>(
        props.createSubscription.customer ? props.createSubscription.customer.name : undefined
    );
    const [nameError, setNameError] = useState<boolean | undefined>(undefined);
    const [password, setPassword] = useState<string>(props.createSubscription.password);
    const [passwordError, setPasswordError] = useState<boolean | undefined>(undefined);

    const onChangeEmail = (value: string): void => {
        setEmail(value != null ? value.trim() : value);
    };
    const validateEmailInput = (value: string): boolean => {
        const hasError: boolean = !validateEmail(value);
        setEmailErrorText(props.t('registerSubscription.loginStep.errors.email'));
        setEmailError(hasError);
        return hasError;
    };
    const onChangeName = (value: string): void => {
        setName(value);
    };
    const validateName = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length <= 3;
        setNameError(hasError);
        return hasError;
    };
    const onChangePassword = (value: string): void => {
        setPassword(value);
    };
    const validatePassword = (value?: string): boolean => {
        const hasError: boolean = value == null || value.trim().length < 4;
        setPasswordError(hasError);
        return hasError;
    };

    const onNextStep = async () => {
        const emailAlreadyExists = await RegisterService.checkIfEmailAlreadyRegistered(email);
        if(emailAlreadyExists) {
            toaster.notify(
                callback => <CustomToast title={props.t('register.error.email')} onClose={callback.onClose} t={props.t} />,
                {
                    duration: 3000,
                    position: 'bottom'
                }
            );
            return;
        }
        let hasError: boolean;
        hasError = validateEmailInput(email || '') || validateName(name) || validatePassword(password) || emailAlreadyExists;
        const createSubscription: CustomerRegister = {
            ...props.createSubscription,
            email,
            password,
            customer: {
                ...props.createSubscription.customer,
                name
            }
        };
        props.onNextStep(createSubscription, hasError);
    };
    return (
        <div className="login-step">
            <Row>
                <Col md={{ size: '6', offset: '3' }} xs={{ size: '12' }}>

                    <div className="login-step__inputs">
                        <FormControl className="form" style={{ width: 500, paddingLeft: 60, paddingRight: 50 }}>
                            <div style={{ display: 'none' }}>
                                <input type="password" tabIndex={-1} />
                            </div>
                            <TextFieldMeds
                                onChange={onChangeName}
                                id="name"
                                value={name}
                                errorText={props.t('registerSubscription.loginStep.errors.name')}
                                label={props.t('register.form.name')}
                                placeholder={props.t('register.form.namePlaceholder')}
                                error={nameError}
                                onBlur={() => validateName(name)}
                            />
                            <TextFieldMeds
                                onChange={onChangeEmail}
                                id="email"
                                isEmail
                                value={email}
                                errorText={emailErrorText}
                                label={props.t('register.form.username')}
                                placeholder={props.t('register.form.usernamePlaceholder')}
                                error={emailError}
                                onBlur={() => validateEmailInput(email || '')}
                            />
                            <TextFieldMeds
                                onChange={onChangePassword}
                                id="password"
                                value={password}
                                isPassword
                                helpText={props.t('register.form.helpPass')}
                                errorText={props.t('registerSubscription.loginStep.errors.password')}
                                label={props.t('register.form.password')}
                                placeholder={props.t('register.form.passwordPlaceholder')}
                                onEnterPress={onNextStep}
                                error={passwordError}
                                onBlur={() => validatePassword(password)}
                                InputProps={{
                                    autComplete: 'off'
                                }}
                            />
                        </FormControl>
                    </div>
                </Col>
            </Row>
            <div className="steps__footer">
                <Button
                    onPress={onNextStep}
                    style={{ width: 400 }}
                    label={props.t('diagnApp.advance')}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LoginStep) as React.ComponentType<Omit<Props, 't'>>;

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CustomToast from '../../components/custom-toast/custom-toast';
import { Customer } from '../../model/customer';
import { CustomerRegister } from '../../model/customer-register';
import Formation from '../../model/enums/formation';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { IRootState } from '../../reducer';
import { registerRequest, reset } from '../../reducer/register/actions';
import RegisterService from '../../services/registerService';
import DataStep from './components/data-step';
import LoginStep from './components/login-step';
import './register.scss';
import { StepItem } from './steps';

export interface RegisterSubscriptionProps extends RouteComponentProps<{}> {
    t: any;
    register: (customer: CustomerRegister) => void;
    reset: () => void;
    registerStatus: HttpRequestStatus;
    isAuthenticated: boolean;
}

enum StepType {
    LOGIN,
    DATA,
    FINISH
}

const RegisterSubscription = (props: RegisterSubscriptionProps) => {
    const [steps, setSteps] = useState<StepItem[]>([]);
    const [showErrors, setShowErrors] = useState<boolean>();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [createSubscription, setCreateSubscription] = useState<CustomerRegister>({
        email: '',
        password: '',
        customer: {
            formation: Formation.PROFESSIONAL
        } as Customer
    });

    useEffect(() => {
        setSteps([
            {
                title: props.t('register.loginStep.title')
            },
            {
                title: props.t('register.dataStep.title')
            }
        ]);
    }, []);

    const onNextStep = (newCreateSubscription: CustomerRegister, hasError: boolean, errorMessage?: string): void => {
        setCreateSubscription(newCreateSubscription);
        if (hasError) {
            setShowErrors(true);
            toaster.notify(
                callback => <CustomToast title={errorMessage || props.t('register.error.step-1')} onClose={callback.onClose} t={props.t} />,
                {
                    duration: 3000,
                    position: 'bottom'
                }
            );
            return;
        }
        switch (currentIndex) {
            case StepType.LOGIN:
                props.reset();
                setShowErrors(undefined);
                setCurrentIndex(currentIndex + 1);
                break;
            case StepType.DATA:
                register(newCreateSubscription);
                break;
            default:
                break;
        }
    };

    const register = (newCreateSubscription: CustomerRegister) => {
        props.register(newCreateSubscription);
    };

    const onBack = (): void => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const renderLoginStep = () => {
        return (
            <LoginStep
                onNextStep={onNextStep}
                showErrors={showErrors}
                createSubscription={createSubscription}
            />
        );
    };

    const renderDataStep = () => {
        return (
            <DataStep
                onBack={onBack}
                onNextStep={onNextStep}
                showErrors={showErrors}
                history={props.history}
                createSubscription={createSubscription}
                finished={props.registerStatus === HttpRequestStatus.SUCCESS}
            />
        );
    };

    return (
        <div className="register-subscription">
            <div className="register-subscription__header">
                <div onClick={() => props.history.push('/')} className="register-subscription__header__logo" />
            </div>
            {steps.length > 0 && (
                <div className="steps">
                    {props.registerStatus !== HttpRequestStatus.SUCCESS && (
                        <div className={'step-header-register'}>
                            <div className={'caret-arrow'} onClick={onBack} />
                            <span className={'step-title'}>{steps[currentIndex].title}</span>
                        </div>
                    )}
                    <div className="steps__content container">{currentIndex === StepType.LOGIN ? renderLoginStep() : renderDataStep()}</div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ register, authentication }: IRootState) => ({
    registerStatus: register.registerStatus,
    isAuthenticated: authentication.isAuthenticated
});

const mapDispatchToProps = {
    register: registerRequest,
    reset: reset
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(RegisterSubscription) as React.ComponentType<any>;

import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import Button from '../../../components/button/button';
import CustomToast from '../../../components/custom-toast/custom-toast';
import Dropdown from '../../../components/dropdown/dropdown';
import WemedsTextField from '../../../components/text-field-meds/text-field-meds';
import CepService from '../../../services/cep.service';
import { MaskNumber, MaskZipCode } from '../../../shared/util/mask-utils';
import { STATES } from '../../../shared/util/states';
import { useSubscriptionContext } from '../subscription.provider';

export interface Props {
    t: (path: string, params?: any) => string;
    onBack: () => void;
}

interface AddressErrors {
    streetError?: boolean;
    streetNumberError?: boolean;
    districtError?: boolean;
    cityError?: boolean;
    stateError?: boolean;
    zipCodeError?: boolean;
}

export const AddressStep = (props: Props): JSX.Element => {
    const {
        address,
        setAddress,
        setCreateSubscription,
        createSubscription,
        setCurrentIndex,
        currentIndex
    } = useSubscriptionContext();
    const [errors, setErrors] = useState<AddressErrors>({});
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [showNote, setShowNote] = useState<boolean>(true);

    useEffect(() => {
        if (address.street != null) {
            setTimeout(() => setErrors({
                streetError: validateStreet(),
                cityError: validateCity(),
                stateError: validateState(),
                zipCodeError: validateZipCode(),
                streetNumberError: validateStreetNumber(),
                districtError: validateDistrict()
            }), 500);
        }
    }, []);

    useEffect(() => {
        if (address.zipCode != null && address.zipCode !== '') {
            const delayDebounceFn = setTimeout(() => {
                if (!validateZipCode()) {
                    CepService.getAddressByZipCode(address.zipCode!).then(newAddress => {
                        setAddress({
                            ...address,
                            street: newAddress.street,
                            city: newAddress.city,
                            district: newAddress.district,
                            state: newAddress.state
                        });
                        setErrors({
                            ...errors,
                            zipCodeError: false,
                            districtError: false,
                            streetError: false,
                            cityError: false,
                            stateError: false
                        });
                    });
                }
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [address.zipCode]);

    useEffect(() => {
        window.fbq('track', 'InitiateCheckout');
    }, []);

    const validateStreet = (): boolean => {
        const hasError: boolean = address.street == null || address.street.trim().length === 0;
        setErrors({ ...errors, streetError: hasError });
        return hasError;
    };
    const validateStreetNumber = (): boolean => {
        const hasError: boolean = address.streetNumber == null || address.streetNumber.trim().length === 0;
        setErrors({ ...errors, streetNumberError: hasError });
        return hasError;
    };
    const validateDistrict = (): boolean => {
        const hasError: boolean = address.district == null || address.district.trim().length === 0;
        setErrors({ ...errors, districtError: hasError });
        return hasError;
    };
    const validateCity = (): boolean => {
        const hasError: boolean = address.city == null || address.city.trim().length === 0;
        setErrors({ ...errors, cityError: hasError });
        return hasError;
    };
    const validateState = (): boolean => {
        const hasError: boolean = address.state == null || address.state.trim().length === 0;
        setErrors({ ...errors, stateError: hasError });
        return hasError;
    };
    const validateZipCode = (): boolean => {
        const regex = /^\d{5}-?\d{3}$/;
        const hasError: boolean = address.zipCode == null || !regex.test(address.zipCode.trim());
        setErrors({ ...errors, zipCodeError: hasError });
        return hasError;
    };

    const onChangeCheckbox = (event: any): void => {
        const {
            target: { checked }
        } = event;
        setCheckbox(checked);
    };

    const onNextStep = (): void => {
        const isValid: boolean = !validateStreet() &&
            !validateStreetNumber() &&
            !validateDistrict() &&
            !validateCity() &&
            !validateState() &&
            !validateZipCode();
        if (isValid) {
            setCreateSubscription({
                ...createSubscription,
                user: { ...createSubscription.user, customer: { address: { ...address, country: 'BR' } } }
            });
            if (isValid && !checkbox) {
                toaster.notify(callback => <CustomToast
                    title={props.t('registerSubscription.addressStep.errors.checkbox')}
                    onClose={callback.onClose} t={props.t} />, {
                    duration: 3000,
                    position: 'bottom'
                });
                return;
            }
            setCurrentIndex(currentIndex + 1);
        }
    };

    return (
        <div className="address-step">
            <div className="premium-logo" />
            <div className="login-step__inputs-mobile-done">
                <div style={{ display: 'flex', marginLeft: '23px' }}>
                    <div className="login-step__inputs-mobile-done--icon-done" />
                    <div className="login-step__inputs-mobile-done--text">{props.t('register.loginStep.title')}</div>
                </div>
                <div style={{ display: 'flex', marginRight: '23px' }}>
                    <div className="login-step__inputs-mobile-done--edit">{props.t('register.step.edit')}</div>
                    <div className="login-step__inputs-mobile-done--icon-arrow" />
                </div>
            </div>
            <Row style={{ width: '100%' }}>
                <Col>
                    <div className="login-step__inputs-mobile">
                        <div style={{ display: 'flex' }}>
                            <div className="login-step__inputs-mobile--icon-active" />
                            <div
                                className="login-step__inputs-mobile--text">{props.t('registerSubscription.addressStep.title')}</div>
                        </div>
                        <div className="login-step__inputs-mobile--icon-arrow" />
                    </div>
                </Col>
            </Row>
            <div className="address-step__inputs">
                <Row style={{ marginTop: '25px' }}>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <WemedsTextField
                            id="zipCode"
                            value={address.zipCode}
                            errorText={props.t('preferences.profileComponent.zipcodeError')}
                            label={props.t('preferences.profileComponent.zipcodePlaceholder')}
                            placeholder={props.t('preferences.profileComponent.zipcode')}
                            error={errors.zipCodeError}
                            InputProps={{
                                inputComponent: MaskZipCode,
                                value: address.zipCode
                            }}
                            onBlur={validateZipCode}
                            onChange={value => setAddress({ ...address, zipCode: value })}
                        />
                    </Col>
                    <Col>
                        {showNote && (
                            <div className="register-subscription__note">
                                <span className="register-subscription__note__text">
                                    {props.t('registerSubscription.addressStep.sideNoteText')}
                                </span>
                                <div className="register-subscription__note__close-icon"
                                     onClick={() => setShowNote(false)} />
                            </div>
                        )}
                    </Col>
                </Row>
                <Row className={'street_row_container'}>
                    <Col md={{ size: '4', offset: '3' }} xs={{ size: '8' }}>
                        <WemedsTextField
                            id="street"
                            value={address.street}
                            errorText={props.t('preferences.profileComponent.addressError')}
                            label={props.t('preferences.profileComponent.addressPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.address')}
                            error={errors.streetError}
                            onBlur={validateStreet}
                            onChange={value => setAddress({ ...address, street: value })}
                        />
                    </Col>
                    <Col md="2" xs="4" style={{ paddingLeft: '0' }}>
                        <WemedsTextField
                            id="number"
                            value={address.streetNumber}
                            errorText={props.t('preferences.profileComponent.numberError')}
                            label={props.t('preferences.profileComponent.numberPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.number')}
                            error={errors.streetNumberError}
                            InputProps={{
                                inputComponent: MaskNumber,
                                value: address.streetNumber
                            }}
                            onBlur={validateStreetNumber}
                            onChange={value => setAddress({ ...address, streetNumber: value })}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '24px' }}>
                    <Col md={{ size: '3', offset: '3' }} style={{ paddingRight: '7.5px' }} xs="6">
                        <WemedsTextField
                            id="complement"
                            value={address.complement}
                            label={props.t('preferences.profileComponent.complementPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.complement')}
                            error={address.complement != null ? false : undefined}
                            onChange={value => setAddress({ ...address, complement: value })}
                        />
                    </Col>
                    <Col md="3" xs="6" style={{ paddingLeft: '7.5px' }}>
                        <WemedsTextField
                            id="city"
                            value={address.city}
                            errorText={props.t('preferences.profileComponent.cityError')}
                            label={props.t('preferences.profileComponent.cityPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.city')}
                            error={errors.cityError}
                            onBlur={validateCity}
                            onChange={value => setAddress({ ...address, city: value })}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col md={{ size: '3', offset: '3' }} style={{ paddingRight: '7.5px' }} xs="6">
                        <WemedsTextField
                            id="district"
                            value={address.district}
                            errorText={props.t('preferences.profileComponent.neighborhoodError')}
                            label={props.t('preferences.profileComponent.neighborhoodPlaceholder')}
                            placeholder={props.t('preferences.profileComponent.neighborhood')}
                            error={errors.districtError}
                            onBlur={validateDistrict}
                            onChange={value => setAddress({ ...address, district: value })}
                        />
                    </Col>
                    <Col md="3" xs="6" style={{ paddingLeft: '7.5px' }}>
                        <Dropdown
                            data={STATES.map(it => ({ label: it, value: it }))}
                            onClickItem={value => setAddress({ ...address, state: value })}
                            title={props.t('preferences.profileComponent.statePlaceholder')}
                            initialValue={address?.state}
                            selectedItem={address.state}
                            error={errors.stateError}
                            errorText={props.t('registerSubscription.addressStep.errors.state')}
                        />
                    </Col>
                </Row>
                <div className={'mobile-note-container'}>
                    <Row>
                        <Col md={{ size: '6', offset: '5' }} xs="12">
                            <div className="mobile-address-note">
                                <span className="mobile-address-note__label">
                                    ({props.t('registerSubscription.addressStep.sideNoteText')})
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={{ size: '6', offset: '3' }} xs="12">
                        <div className="address-step__checkbox">
                            <Checkbox onChange={onChangeCheckbox} checked={checkbox} />
                            <span>
                                <span className="address-step__checkbox__label">
                                    {props.t('registerSubscription.addressStep.checkboxLabel1')}
                                </span>
                                <a href="https://wemeds.com.br/termos/" target="_blank"
                                   className="address-step__checkbox__label--orange">
                                    {props.t('registerSubscription.addressStep.checkboxLabel2')}
                                </a>
                            </span>
                        </div>
                        <div className="steps__footer">
                            <Button
                                label={props.t('diagnApp.back')}
                                inverse
                                onPress={props.onBack}
                                style={{ width: 151, marginRight: 13 }}
                                leftIcon={<div className="steps__footer__back-icon" />}
                            />
                            <Button
                                label={props.t('diagnApp.advance')}
                                onPress={onNextStep}
                                style={{ width: 151, marginLeft: 13 }}
                                rightIcon={<div className="steps__footer__advance-icon" />}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="login-step__inputs-mobile-disabled--all">
                <div className="login-step__inputs-mobile-disabled">
                    <div
                        className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.paymentStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
                <div className="login-step__inputs-mobile-disabled">
                    <div
                        className="login-step__inputs-mobile-disabled--text">{props.t('registerSubscription.finishStep.title')}</div>
                    <div className="login-step__inputs-mobile-disabled--icon-arrow" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AddressStep) as React.ComponentType<
    Omit<Props, 't'>
>;

import React from 'react';
import MediaQuery from 'react-responsive';
import { Route } from 'react-router-dom';
import Cid from '../../features/cid/cid';
import CompanyCreateLogin from '../../features/company-dashboard/company-create-login';
import CompanyDashboard from '../../features/company-dashboard/company-dashboard';
import Construction from '../../features/construction/construction';
import ContentRatingScreen from '../../features/content-rating/content-rating';
import DiagnApp from '../../features/diagnapp/diagnapp';
import Disease from '../../features/disease/disease';
import { DividerOnDutyProvider } from '../../features/divider-on-duty/context/divider-on-duty-provider';
import DividerOnDuty from '../../features/divider-on-duty/divider-on-duty';
import EditorContent from '../../features/editor-content/editor-content';
import { FlashcardProvider } from '../../features/flashcard/flashcard-carousel/flashcard.provider';
import FlashcardCustom from '../../features/flashcard/flashcard-custom/flashcard-custom';
import FlashcardDashboard from '../../features/flashcard/flashcard-dashboard/flashcard-dashboard';
import FlashcardDashboardStatistic
    from '../../features/flashcard/flashcard-dashboard/flashcard-dashboard-statistic/flashcard-dashboard-statistic';
import FlashcardExtensiveCreate
    from '../../features/flashcard/flashcard-extensive-dashboard/flashcard-extensive-create';
import FlashcardExtensiveDashboard
    from '../../features/flashcard/flashcard-extensive-dashboard/flashcard-extensive-dashboard';
import FlashcardFavorites from '../../features/flashcard/flashcard-favorites/flashcard-favorites';
import FlashcardIntensiveCreate
    from '../../features/flashcard/flashcard-intensive-dashboard/flashcard-intensive-create';
import FlashcardIntensiveDashboard
    from '../../features/flashcard/flashcard-intensive-dashboard/flashcard-intensive-dashboard';
import MyFlashcardForm from '../../features/flashcard/my-flashcards/my-flashcard-form';
import MyFlashcards from '../../features/flashcard/my-flashcards/my-flashcards';
import FlashcardCustomPractice from '../../features/flashcard/practice/flashcard-custom/flashcard-custom-practice';
import FlashcardDisease from '../../features/flashcard/practice/flashcard-disease/flashcard-disease';
import FlashcardExtensiveCheckpoint
    from '../../features/flashcard/practice/flashcard-extensive-checkpoint/flashcard-extensive-checkpoint';
import FlashcardExtensive from '../../features/flashcard/practice/flashcard-extensive/flashcard-extensive';
import FlashcardFavorite from '../../features/flashcard/practice/flashcard-favorite/flashcard-favorite';
import FlashcardIntensive from '../../features/flashcard/practice/flashcard-intensive/flashcard-intensive';
import FlashcardRandom from '../../features/flashcard/practice/flashcard-random/flashcard-random';
import MyFlashcard from '../../features/flashcard/practice/my-flashcard/my-flashcard';
import HistoryRecentScreen from '../../features/history-recent/history-recent';
import Home from '../../features/home/home';
import IllustratedProcedureScreen from '../../features/illustrated-procedures/content/illustrated-procedure';
import IllustratedProcedures from '../../features/illustrated-procedures/illustrated-procedures';
import LaboratoryInfoScreen from '../../features/laboratory/info/laboratory-info';
import Laboratory from '../../features/laboratory/laboratory';
import Login from '../../features/login/login';
import MedicalCalculatorDashboard from '../../features/medical-calculator/medical-calculator-dashboard';
import { MedicalCalculatorProvider } from '../../features/medical-calculator/medical-calculator.provider';
import MedicalCriteriaDashboard from '../../features/medical-criteria/medical-criteria-dashboard';
import { MedicalCriteriaProvider } from '../../features/medical-criteria/medical-criteria.provider';
import MedicalScoreDashboard from '../../features/medical-score/medical-score-dashboard';
import { MedicalScoreProvider } from '../../features/medical-score/medical-score.provider';
import MedicineInteractions from '../../features/medicine-interactions/medicine-interactions';
import PediatricDoseDashboard from '../../features/pediatric-dose/pediatric-dose-dashboard';
import { PediatricDoseProvider } from '../../features/pediatric-dose/pediatric-dose.provider';
import Preferences from '../../features/preferences/preferences';
import Protocols from '../../features/protocols/protocols';
import RegisterSubscription from '../../features/register-subscription/register-subscription';
import Subscription from '../../features/register-subscription/Subscription';
import { SubscriptionProvider } from '../../features/register-subscription/subscription.provider';
import ActiveRegister from '../../features/register/active-register';
import RegisterMobile from '../../features/register/mobile/registerMobile';
import Register from '../../features/register/register';
import Search from '../../features/search/search';
import SemiologyInfoScreen from '../../features/semiology/info/semiology-info';
import Semiology from '../../features/semiology/semiology';
import SmartLeaflet from '../../features/smart-leaflet/smart-leaflet';
import SmartLeaflets from '../../features/smart-leaflets/smart-leaflets';
import Specialty from '../../features/specialties/specialty';
import Sus from '../../features/sus/sus';
import Toxicology from '../../features/toxicology/toxicology';
import Vaccination from '../../features/vaccination/vaccination';
import PrivateRoute from './privateRoutes';

const AppRoutes = ({ match }) => {
    return (
        <div>
            {/* <PrivateRoute path="/app" component={FeatureRoutes} /> */}
            {/* Web */}
            <MediaQuery minDeviceWidth={819}>
                <Route exact path={`${match.url}`} component={Login} />
                <Route exact path={`/register`} component={Register} />
                <Route exact path={`/active-register/:key`} component={ActiveRegister} />
                <Route
                    exact
                    path={`/register-subscription`}
                    component={props => {
                        return (
                            <SubscriptionProvider>
                                <RegisterSubscription {...props} />
                            </SubscriptionProvider>
                        );
                    }}
                />
                <PrivateRoute
                    exact
                    path={`/subscription`}
                    component={props => {
                        return (
                            <SubscriptionProvider>
                                <Subscription {...props} />
                            </SubscriptionProvider>
                        );
                    }}
                />
            </MediaQuery>

            {/* Mobile */}
            <MediaQuery maxDeviceWidth={818}>
                <Route exact path={`${match.url}`} component={RegisterMobile} />
                <Route exact path={`/register`} component={RegisterMobile} />
                <PrivateRoute
                    exact
                    path={`/register-subscription`}
                    component={props => {
                        return (
                            <SubscriptionProvider>
                                <RegisterSubscription {...props} />
                            </SubscriptionProvider>
                        );
                    }}
                />
                <PrivateRoute
                    exact
                    path={`/subscription`}
                    component={props => {
                        return (
                            <SubscriptionProvider>
                                <Subscription {...props} />
                            </SubscriptionProvider>
                        );
                    }}
                />
            </MediaQuery>

            <PrivateRoute exact path={`/company-create-login`} component={CompanyCreateLogin} additionalVerify />
            <PrivateRoute exact path={`/company-dashboard`} component={CompanyDashboard} additionalVerify />
            <PrivateRoute exact path={`/home`} component={Home} />
            <PrivateRoute exact path={`/specialty`} component={Specialty} />
            <PrivateRoute exact path={`/vaccination`} component={Vaccination} />
            <PrivateRoute exact path={`/toxicology`} component={Toxicology} />
            <PrivateRoute exact path={`/flashcard-dashboard`} component={FlashcardDashboard} />
            <PrivateRoute exact path={`/flashcard-extensive-dashboard`} component={FlashcardExtensiveDashboard} />
            <PrivateRoute exact path={`/flashcard-extensive-create`} component={FlashcardExtensiveCreate} />
            <PrivateRoute exact path={`/flashcard-intensive-dashboard`} component={FlashcardIntensiveDashboard} />
            <PrivateRoute exact path={`/flashcard-intensive-create`} component={FlashcardIntensiveCreate} />
            <PrivateRoute exact path={`/flashcard-dashboard-statistic`} component={FlashcardDashboardStatistic} />
            <PrivateRoute exact path={`/my-flashcard-form/:myFlashcardId`} component={MyFlashcardForm} />
            <PrivateRoute exact path={`/my-flashcard-form`} component={MyFlashcardForm} />
            <PrivateRoute exact path={`/flashcard-custom`} component={FlashcardCustom} />
            <PrivateRoute exact path={`/semiology`} component={Semiology} />
            <PrivateRoute exact path={`/semiology/:semiologyId`} component={SemiologyInfoScreen} />
            <PrivateRoute
                exact
                path={`/calculator/:medicalCalculatorId`}
                component={props => {
                    return (
                        <MedicalCalculatorProvider>
                            <MedicalCalculatorDashboard {...props} />
                        </MedicalCalculatorProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/calculator`}
                component={props => {
                    return (
                        <MedicalCalculatorProvider>
                            <MedicalCalculatorDashboard {...props} />
                        </MedicalCalculatorProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-disease/:id`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardDisease {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-custom/practice`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardCustomPractice {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-favorite`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardFavorites {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/my-flashcard`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <MyFlashcard {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/my-flashcards`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <MyFlashcards {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-favorite/practice`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardFavorite {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-extensive/:metricId`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardExtensive {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-extensive/checkpoint/:metricId`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardExtensiveCheckpoint {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-intensive/:metricId`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardIntensive {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/flashcard-random`}
                component={props => {
                    return (
                        <FlashcardProvider>
                            <FlashcardRandom {...props} />
                        </FlashcardProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/criteria`}
                component={props => {
                    return (
                        <MedicalCriteriaProvider>
                            <MedicalCriteriaDashboard {...props} />
                        </MedicalCriteriaProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/criteria/:criteriaId`}
                component={props => {
                    return (
                        <MedicalCriteriaProvider>
                            <MedicalCriteriaDashboard {...props} />
                        </MedicalCriteriaProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/scores`}
                component={props => {
                    return (
                        <MedicalScoreProvider>
                            <MedicalScoreDashboard {...props} />
                        </MedicalScoreProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/scores/:scoreId`}
                component={props => {
                    return (
                        <MedicalScoreProvider>
                            <MedicalScoreDashboard {...props} />
                        </MedicalScoreProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/pediatric-dose`}
                component={props => {
                    return (
                        <PediatricDoseProvider>
                            <PediatricDoseDashboard {...props} />
                        </PediatricDoseProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/pediatric-dose/:pediatricDoseId`}
                component={props => {
                    return (
                        <PediatricDoseProvider>
                            <PediatricDoseDashboard {...props} />
                        </PediatricDoseProvider>
                    );
                }}
            />
            <PrivateRoute
                exact
                path={`/divider-on-duty`}
                component={props => {
                    return (
                        <DividerOnDutyProvider>
                            <DividerOnDuty {...props} />
                        </DividerOnDutyProvider>
                    );
                }}
            />
            <PrivateRoute exact path={`/laboratory`} component={Laboratory} />
            <PrivateRoute exact path={`/laboratory/:laboratoryId`} component={LaboratoryInfoScreen} />
            <PrivateRoute exact path={`/protocols`} component={Protocols} />
            <PrivateRoute exact path={`/diagnapp`} component={DiagnApp} />
            <PrivateRoute exact path={`/smart-bulas`} component={SmartLeaflets} />
            <PrivateRoute exact path={`/smart-bulas/:leafletId`} component={SmartLeaflet} />
            <PrivateRoute exact path={`/interaction`} component={MedicineInteractions} />
            <PrivateRoute exact path={`/procedure`} component={IllustratedProcedures} />
            <PrivateRoute exact path={`/procedure/:procedureId`} component={IllustratedProcedureScreen} />
            <PrivateRoute exact path={`/cid`} component={Cid} />
            <PrivateRoute exact path={`/cid/:cidId`} component={Cid} />
            <PrivateRoute exact path={`/sus`} component={Sus} />
            <PrivateRoute exact path={`/sus/:susId`} component={Sus} />

            <PrivateRoute exact path={`/search`} component={Search} />
            <PrivateRoute exact path={`/content-rating`} component={ContentRatingScreen} />
            <PrivateRoute exact path={`/recent-history`} component={HistoryRecentScreen} />
            <PrivateRoute exact path={`/editor-content`} component={EditorContent} />

            <PrivateRoute path={`/preferences`} component={Preferences} />

            <PrivateRoute exact path={`/disease/:diseaseId`} component={Disease} />
            <Route exact path="/construction" component={Construction} />
        </div>
    );
};

export default AppRoutes;

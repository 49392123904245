import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import CustomToast from '../../components/custom-toast/custom-toast';
import ErrorPaymentModal from '../../components/error-payment-modal/error-payment-modal';
import { CreateSubscriptionResponse } from '../../model/apiResponses/CreateSubscriptionResponse';
import { CreateSubscription } from '../../model/createSubscription';
import { User } from '../../model/user';
import { IRootState } from '../../reducer';
import { openErrorPaymentModal } from '../../reducer/globalModal/actions';
import AnalyticsService from '../../services/analytics-service';
import SubscriptionService from '../../services/subscriptionService';
import AddressStep from './components/address-step';
import FinishStep from './components/finish-step';
import PaymentStep from './components/payment-step';
import './register-subscription.scss';
import { StepsHeader } from './steps-header';
import { useSubscriptionContext } from './subscription.provider';

export interface SubscriptionProps extends RouteComponentProps<{}> {
    user: User;
    t: any;
}

enum StepType {
    ADDRESS,
    PAYMENT,
    FINISH
}

const Subscription = (props: SubscriptionProps) => {
    const [createSubscriptionResponse, setCreateSubscriptionResponse] = useState<CreateSubscriptionResponse>();
    const [createSubscription, setCreateSubscription] = useState<CreateSubscription>({
        twoYearsPromotion: false,
        newUser: false,
        paymentMethod: {},
        couponCode: undefined,
        user: {}
    });
    const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
    const { currentIndex, setCurrentIndex, setShowFinishPayment } = useSubscriptionContext();
    const [showErrors, setShowErrors] = useState<boolean>();
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [paymentValue, setPaymentValue] = useState<number>(293.88);
    const [errorModalIsOpen, setErrorModalIsOpen] = useState<boolean>(false);

    const onChangePaymentValue = (newPaymentValue: number): void => {
        setPaymentValue(newPaymentValue);
    };

    const onNextStep = (newCreateSubscription: CreateSubscription, newHasErrors: boolean): void => {
        setCreateSubscription(newCreateSubscription);
        setHasErrors(newHasErrors);
        if (hasErrors) {
            setShowErrors(true);
            toaster.notify(callback => <CustomToast title={props.t('register.error.step-1')}
                                                    onClose={callback.onClose} t={props.t} />, {
                duration: 3000,
                position: 'bottom'
            });
            return;
        }
        switch (currentIndex) {
            case StepType.PAYMENT:
                register(newCreateSubscription);
                break;
            case StepType.FINISH:
                AnalyticsService.purchaseEvent(props.user?.email ?? '');
                window.fbq('track', 'Purchase');
                props.history.push('/');
                break;
            default:
                break;
        }
    };

    const renderStep = () => {
        switch (currentIndex) {
            case StepType.ADDRESS:
                return <AddressStep onBack={onBack} />;
            case StepType.PAYMENT:
                return <PaymentStep
                    onNextStep={onNextStep}
                    isLoadingPayment={isLoadingPayment}
                    onBack={onBack}
                    showErrors={showErrors}
                    paymentValue={paymentValue}
                    onChangePaymentValue={onChangePaymentValue}
                />;
            case StepType.FINISH:
                return <FinishStep
                    createSubscription={createSubscription}
                    onNextStep={onNextStep}
                    isRegisterFlux={false}
                    onBack={onBack}
                    showErrors={showErrors}
                    createSubscriptionResponse={createSubscriptionResponse!}
                />;
            default:
                return <div />;
        }
    };

    const register = async (newCreateSubscription: CreateSubscription): Promise<void> => {
        setIsLoadingPayment(true);
        SubscriptionService.createSubscription(newCreateSubscription)
            .then(save => {
                const response: CreateSubscriptionResponse = { totalPaid: save };
                setCreateSubscriptionResponse(response);
                setShowErrors(false);
                setCurrentIndex(StepType.FINISH);
                setIsLoadingPayment(false);
            })
            .catch(() => {
                setIsLoadingPayment(false);
                setErrorModalIsOpen(true);
            });
    };

    const onBack = (): void => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else if (currentIndex === 0) {
            props.history.goBack();
        }
    };

    const titles: string[] = [props.t('registerSubscription.addressStep.title'), props.t('registerSubscription.paymentStep.title'), props.t('registerSubscription.finishStep.title')];

    return (
        <div className="register-subscription">
            <ErrorPaymentModal open={errorModalIsOpen} close={() => setErrorModalIsOpen(false)}
                               onPressFirstButton={() => register(createSubscription)} onPressSecondButton={() => {
                setCurrentIndex(1);
                setShowFinishPayment(false);
            }} history={props.history} />
            <div className="register-subscription__header">
                <div onClick={() => props.history.push('/')} className="register-subscription__header__logo" />
            </div>
            <div className="steps">
                <StepsHeader titles={titles} />
                <div className="steps__content container">{renderStep()}</div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    user: authentication.account
});

const mapDispatchToProps = {
    openModal: openErrorPaymentModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Subscription) as React.ComponentType<any>;

import { InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import './phone-field-meds.scss';
import { CountryIso2, defaultCountries, FlagEmoji, parseCountry, usePhoneInput } from 'react-international-phone';

export interface IWemedsPhoneFieldProps {
    placeholder?: string;
    label?: string;

    value?: string;

    error?: boolean;
    errorText?: string;

    onChange: (value: string) => void;
    onError?: (value: boolean) => void;
    style?: any;
    onBlur?: () => void;
    InputProps?: any;
}

const WemedsPhoneField = (props: IWemedsPhoneFieldProps) => {
    const [filled, setFilled] = useState<boolean>(props.value != null);
    const [focused, setFocused] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(props.placeholder != null ? props.placeholder : props.label != null ? props.label : '');

    const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
        usePhoneInput({
            defaultCountry: 'us',
            value: props.value,
            countries: defaultCountries,
            onChange: (data) => {
                props.onChange(data.phone);
            }
        });

    const onBlur = (): void => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur();
        }
    };


    const { error, errorText, InputProps, style } = props;
    const didNotValidated = filled && (error == null || !focused);
    const hasError = error === true && !focused;
    const isValid = error === false && !focused;
    return (
        <div className="phone__field" style={{ position: 'relative', width: '100%', ...style }}>
            <TextField
                variant="outlined"
                label={label}
                fullWidth
                autoComplete="off"
                error={hasError}
                onBlur={onBlur}
                placeholder={label}
                className={
                    hasError
                        ? 'error filled MuiFilledInput-underline'
                        : isValid
                            ? 'filled validate'
                            : didNotValidated
                                ? 'filled'
                                : 'filled'
                }
                value={phone}
                onChange={handlePhoneValueChange}
                helperText={errorText && hasError ? errorText : null}
                type="tel"
                inputRef={inputRef}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            style={{ marginRight: '2px', marginLeft: '-8px' }}
                        >
                            <Select
                                MenuProps={{
                                    style: {
                                        height: '300px',
                                        width: '360px',
                                        top: '10px',
                                        left: '-34px'
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }
                                }}
                                className={
                                    hasError
                                        ? 'error filled MuiFilledInput-underline'
                                        : isValid
                                            ? 'filled validate'
                                            : didNotValidated
                                                ? 'filled'
                                                : 'action'
                                }
                                value={country}
                                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                                renderValue={(value) => (
                                    <FlagEmoji iso2={country} style={{ display: 'flex' }} />
                                )}
                            >
                                {defaultCountries.map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                        <MenuItem key={country.iso2} value={country.iso2}>
                                            <FlagEmoji
                                                iso2={country.iso2}
                                                style={{ marginRight: '8px' }}
                                            />
                                            <Typography>{country.name}</Typography>
                                            <Typography>+{country.dialCode}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};

export default WemedsPhoneField;
